import { LoadedUsedCompareCarType } from "../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import { DealerResultType } from "../../../common-deprecated/utils/dealerConstants";
import { LegalQuickSpecConfigType, UscQuickSpec, UscVehicleUrlInfo } from "../utils/uscUtils";
import {
    DfDocumentCategory,
    DfDocumentType,
    DfEfficiencyClassCode,
    DfEquipmentCategoryCode,
    DfExternalProductAttr,
    DfExternalVehicleAttrs,
    DfFuelTypeId,
    DfPreviousUsageCode,
    DfPricingSourceCode,
    DfServiceHistoryCode,
    DfVehicleStatus,
} from "../utils/dfConstants";
import {
    BooleanStringType,
    FinanceInfoType,
    UsedCarsTranslationResultType,
} from "../../../common-deprecated/types/CommonTypes";
import { EcoMeasureType, UscEcoType } from "../utils/uscEcoUtils";
import { SpecSection } from "../../pdp/common/utils/constants";
import { UscLabelType } from "../../pdp/v2/utils/labels";
import { AEMBannerDescriptionItemType } from "../../../common-deprecated/types/SettingsType";

export type LocalDealerFormDataType = {
    vehicleForSaleId: string;
    brand: string;
    model: string;
    image: string;
    grade: string;
    engine: string;
    cashPrice: number;
    fuelType: string;
    mileage: number;
    firstRegistrationDate: string;
    vin: string;
    dealerName: string;
    dealerAddress: string;
    url: string;
    t1DataLayerModel: string; // Serialized version of the window.t1DataLayer.model object so local implementations can easily reuse it.
    tyCode: string;
    insuranceQuoteId: string;
    financeQuoteId: string;
};

export type ContactDealerClickOptions<T extends VehicleType | LoadedUsedCompareCarType> = {
    url: string;
    commonSettings: CommonSettingsType;
    detailPageUrl: string;
    context: "used" | "stock";
    vehicle: T;
    dealer: DealerResultType | null;
    vehicleUrlInfo: UscVehicleUrlInfo;
    localDealerFormatter: (
        vehicle: T,
        vehicleUrl: string,
        dealer: DealerResultType | null,
        insuranceQuoteId?: string | null,
        financeQuoteId?: string | null,
        tyCode?: string,
    ) => LocalDealerFormDataType;
    tracking: () => void;
    insuranceQuoteId?: string | null;
    financeQuoteId?: string | null;
    tyCode?: string;
};

// ----------------------------------------------------------------------
// USC enums
// ----------------------------------------------------------------------
export enum UscContext {
    Used = "used",
    Stock = "stock",
}

export enum UscDisclaimerType {
    Cash = "cash",
    Finance = "finance",
    Insurance = "insurance",
    ExternalPrice = "tmef", // We use 'TMEF' in Tridion for NMSC clarity,
    Spec = "spec",
    Promotion = "promotion",
    Accessory = "accessory",
}

export enum UscFormDisclaimerType {
    Cash = "cash",
    Finance = "finance",
    Spec = "spec",
}

export enum UscCheckoutType {
    Buy = "buy",
    Reserve = "reserve",
}

// ----------------------------------------------------------------------
// Default insurance
// ----------------------------------------------------------------------

export type TFRUscDefaultInsuranceQuoteType = {
    monthlyPrice: number;
    disclaimer: string;
};
export type TESUscDefaultInsuranceQuoteType = {
    premium: number;
    disclaimer: string;
};

export type UscDefaultInsuranceQuoteType = TFRUscDefaultInsuranceQuoteType | TESUscDefaultInsuranceQuoteType;

// ----------------------------------------------------------------------
// USC Vehicle
// ----------------------------------------------------------------------

export type ReferenceType<T = string> = { description: string; code: T };
export type MeasureType<T = string> = { value: number; unit: ReferenceType<T> };

export type ServiceHistoryEventType = {
    type: ReferenceType<DfServiceHistoryCode>;
    description: string;
    date: string; // YYYY-MM-DD
    officialDealer: boolean;
    mileage: MeasureType;
};
export type VehicleEngineType = {
    description: string;
    // FuelType can have a lot of specific options, usually marketingFuelType is used in frontend but fuelType is used for example in finance/insurance requests.
    fuelType: ReferenceType;
    marketingFuelType: ReferenceType<DfFuelTypeId>;
    displayFuelType: string;
    hybrid: boolean;
    plugin: boolean;
    cylinders?: number;
    powerOutputHorsepower?: MeasureType;
    powerOutputKilowatts?: MeasureType;
    fiscalPower?: MeasureType;
    displacement?: MeasureType;
    engineKey?: ReferenceType;
    engineNumber?: string;
    batteryCapacity?: MeasureType;
};
export type ProductVersionIdentifierType = {
    versionType: ReferenceType;
    versionName: string;
    versionCode: string;
};

/**
 * Align this type as much as possible with 'VehicleType'
 */
export type UsedCarResultType = {
    id: string;
    vehicleStatus: ReferenceType<DfVehicleStatus>;
    sourceCode: string;
    warranty: ReferenceType | null;
    images: { url: string; sequence: number }[];
    mileage: MeasureType | null;
    exteriorColour: string;
    interiorColour: string;
    trimColour: string;
    dealerId: string;
    dealerLocalId: string;
    isHubCar: boolean;
    history: {
        registrationDate: string | null;
        localRegistrationDate?: string | null;
        events: ServiceHistoryEventType[];
        previousAccident: boolean | null;
        previousOwners: number | null;
        previousUsage: ReferenceType<DfPreviousUsageCode> | null;
    } | null;
    promotion: {
        label: string;
        effectiveTo: string;
        effectiveFrom: string;
        legalDisclaimer: string;
        promotionPrice: {
            reclaimableVAT: boolean;
            sellingPriceInclVAT: number;
            sellingPriceExclVAT: number;
        };
    } | null;
    price: {
        sellingPriceInclVAT: number;
        sellingPriceExclVAT: number;
        reclaimableVAT?: boolean;
        pricingSource?: ReferenceType<DfPricingSourceCode>;
    };
    financingOffers?: FinancingOfferType[];
    currencyCode: string; // Used for tracking purposes
    financeInfo: FinanceInfoType | null;
    insuranceQuote: UscDefaultInsuranceQuoteType | null;
    ownershipTax: number | null;
    vehicleValue: {
        marketPrice: number | null;
        cataloguePrice: number | null;
        valuationDate: string | null;
        // Usually this value comes from TMEF, however in some cases (TAF Used) this value needs to be retrieved from somewhere else in which case this is done by DF.
        // Note that deliveryCost can explicitly be "0" or "null" (undefined at DF side) here.
        // At a data level this is a difference (dealer adding a 0 cost vs an error in retrieving delivery costs),
        // however for a frontend perspective we should hide the cost in both cases (this was a TAF req)
        deliveryCost: number | null;
    };
    licensePlate: string;
    vin: string;
    ucProgram: ReferenceType;
    productionDate: string;
    documents?: {
        label: ReferenceType<DfDocumentType>;
        url: string;
        documentCategory: ReferenceType<DfDocumentCategory>;
    }[];
    threeSixtyView?: {
        viewFormat: { code: string };
        parameters: { value: string }[];
    };
    product: {
        brand: ReferenceType;
        model: string;
        modelYear: string;
        localModelCode: string; // Based on subModel.code in DF source.
        bodyType: string;
        versionName: string;
        doors: number;
        seats: number;
        length: MeasureType | null;
        height: MeasureType | null;
        width: MeasureType | null;
        bootCapacity: MeasureType | null;
        grade?: { gradeDescription?: string }; // type EngineType
        carType: ReferenceType;
        engine: VehicleEngineType;
        // ⚠️ We use eco instead of emission as this is much more than just emission values (see formatters)
        eco: UscEcoType | null;
        performance?: {
            maxSpeed?: MeasureType;
            acceleration?: MeasureType;
        };
        transmission: {
            name?: string;
            gearbox: string;
            transmissionType: ReferenceType | null;
        };
        externalProductAttributes: { code: DfExternalProductAttr; value: string }[]; // This is specifically not a ReferenceType as the "description" property is something different and not required.
        productVersionIdentifiers: ProductVersionIdentifierType[];
    };
    enrichmentStatus: {
        unavailabilityReasons?: string[];
    };
    nextInspectionDate: string;
    isPledgedSale: boolean | null;
    externalVehicleAttributes: { code: DfExternalVehicleAttrs; value: string }[]; // This is specifically not a ReferenceType as the "description" property is something different and not required.

    // Only add car-filter specific properties here.
    title: string;
    description: string;
    monthlyPrice: number;
    dealer: DealerResultType | null;
    trackingProductName: string; // Used for tracking purposes, this should match the output of getVehicleFullName in USC.
};

export type FinancingOfferType = {
    financingOfferId?: string;
    product?: string;
    legalDisclaimer?: string;
    monthlyPaymentInclVAT?: number | string;
    monthlyPaymentExclVAT?: number | string;
    downPayment?: string;
    duration?: string;
    interestRate?: string;
    residualValue?: string;
};

// ----------------------------------------------------------------------
// Filter id enums
// ----------------------------------------------------------------------
// Note that carType and fuelType are also part of usedCarType, but they are used as a standalone filter for new cars.

export enum UsedCarFilterId {
    Transmission = "usedCarTransmission",
    Equipment = "usedCarEquipment",
    Colour = "usedCarColour",
    EcoLabel = "usedCarEcoLabel",
    Warranty = "usedCarWarranty",
    License = "usedCarLicense",
    Seats = "usedCarSeats",
    Doors = "usedCarDoors",
    PowerOutput = "usedCarPowerOutput",
    Year = "usedCarYear",
    Mileage = "usedCarMileage",
    Price = "usedCarPrice",
    Location = "usedCarLocation",
    VehicleStatus = "usedCarVehicleStatus",
    EfficiencyClass = "usedCarEfficiencyClass",
    Deliverable = "usedCarDeliverable",
    ModelYear = "usedCarModelYear",
    Brand = "usedCarBrand",
    Model = "usedCarModel",
    Category = "usedCarCategory",
    FuelType = "usedCarFuelType",
    BodyStyle = "usedCarBodyStyle",
    DriveType = "usedCarDriveType",
}

export enum StockCarFilterId {
    Transmission = "stockCarTransmission",
    Equipment = "stockCarEquipment",
    Colour = "stockCarColour",
    Seats = "stockCarSeats",
    Doors = "stockCarDoors",
    PowerOutput = "stockCarPowerOutput",
    Price = "stockCarPrice",
    Location = "stockCarLocation",
    VehicleStatus = "stockCarVehicleStatus",
    Brand = "stockCarBrand",
    Model = "stockCarModel",
    Category = "stockCarCategory",
    FuelType = "stockCarFuelType",
    BodyStyle = "stockCarBodyStyle",
    DriveType = "stockCarDriveType",
}

export type CarFilterIdType = UsedCarFilterId | StockCarFilterId;

// ----------------------------------------------------------------------
// USC Vehicle
// ----------------------------------------------------------------------

export type EquipmentType = {
    name: string;
    type: ReferenceType<DfEquipmentCategoryCode>;
};

export type UscPromotionType = {
    label: string;
    effectiveTo: string;
    effectiveFrom: string;
    legalDisclaimer: string;
    promotionPrice: {
        reclaimableVAT: boolean;
        sellingPriceInclVAT: number;
        sellingPriceExclVAT: number;
    };
};

/**
 * Align this type as much as possible with 'UsedCarResultType'
 */
export type VehicleType = {
    id: string;
    vehicleStatus: ReferenceType<DfVehicleStatus>;
    sourceCode: string;
    warranty: ReferenceType | null;
    images: { url: string; sequence: number }[];
    mileage: MeasureType | null;
    exteriorColour: string;
    interiorColour: string;
    trimColour: string;
    dealerId: string;
    dealerLocalId: string;
    isHubCar: boolean;
    history: {
        registrationDate: string | null; // YYYY-MM-DD
        localRegistrationDate?: string | null; // Used as a fallback for registrationDate in TAF (OR-8028)
        events: ServiceHistoryEventType[];
        previousAccident: boolean | null;
        previousOwners: number | null;
        previousUsage: ReferenceType<DfPreviousUsageCode> | null;
    } | null;
    promotion: UscPromotionType | null;
    // For more information on forceClientRefresh, refer to fetchUtils
    price: (GqlVehicleResultType["price"] & { forceClientRefresh?: boolean }) | null;
    financingOffers: GqlVehicleResultType["financingOffers"];
    currencyCode: string; // Used for tracking purposes
    financeInfo: FinanceInfoType | null;
    insuranceQuote: UscDefaultInsuranceQuoteType | null;
    ownershipTax: number | null;
    vehicleValue: {
        marketPrice: number | null;
        cataloguePrice: number | null;
        valuationDate: string | null;
        // Usually this value comes from TMEF, however in some cases (TAF Used) this value needs to be retrieved from somewhere else in which case this is done by DF.
        // Note that deliveryCost can explicitly be "0" or "null" (undefined at DF side) here.
        // At a data level this is a difference (dealer adding a 0 cost vs an error in retrieving delivery costs),
        // however for a frontend perspective we should hide the cost in both cases (this was a TAF req)
        deliveryCost: number | null;
    };
    licensePlate: string;
    vin: string;
    ucProgram: ReferenceType;
    productionDate: string; // Used for stock car 'year' field.
    documents?: {
        label: ReferenceType<DfDocumentType>;
        url: string;
        documentCategory: ReferenceType<DfDocumentCategory>;
    }[];
    threeSixtyView?: {
        viewFormat: { code: string };
        parameters: { value: string }[];
    };
    product: {
        brand: ReferenceType;
        model: string;
        modelYear: string;
        localModelCode: string; // Based on subModel.code in DF source.
        bodyType: string;
        versionName: string; // this is most likely engine + some other fields
        orderableSuffix?: { suffixCode: string; padocId: string };
        katashiki?: { fullKatashiki: string };
        doors: number;
        seats: number;
        length: MeasureType | null;
        height: MeasureType | null;
        width: MeasureType | null;
        bootCapacity: MeasureType | null;
        grade?: { gradeDescription?: string };
        carType: ReferenceType;
        engine: VehicleEngineType;
        // ⚠️ We use eco instead of emission as this is much more than just emission values (see formatters)
        eco: UscEcoType | null;
        performance?: {
            maxSpeed?: MeasureType;
            acceleration?: MeasureType;
        };
        transmission: {
            name: string;
            gearbox: string;
            transmissionType?: ReferenceType | null;
        };
        equipment: EquipmentType[];
        externalProductAttributes: { code: DfExternalProductAttr; value: string }[]; // This is specifically not a ReferenceType as the "description" property is something different and not required.
        productVersionIdentifiers: ProductVersionIdentifierType[];
        ssn: string;
        tyreSpecs?: {
            tyres: { eprel: string }[];
            minimumNoise?: MeasureType;
            minimumWetGripClass?: string;
            minimumEfficiencyClass?: string;
        };
    };
    enrichmentStatus?: {
        unavailabilityReasons?: string[];
    };
    nextInspectionDate: string;
    isPledgedSale: boolean | null;
    externalVehicleAttributes: { code: DfExternalVehicleAttrs; value: string }[]; // This is specifically not a ReferenceType as the "description" property is something different and not required.
    monthlyPrice: number;
};

// ----------------------------------------------------------------------
// Type
// ----------------------------------------------------------------------

export type GqlVehicleResultType = {
    vehicleForSaleId: string;
    source: ReferenceType;
    vehicleStatus?: ReferenceType;
    ucWarranty?: ReferenceType;
    mileage?: MeasureType;
    images?: { url: string; sequence: number }[];
    colours: { type?: ReferenceType; displayColour: string }[];
    retailer?: {
        locationType?: ReferenceType;
    };
    // If the retailer has no locationType, retailer will be undefined
    // publishingRetailer is a required property but typed as optional. See 'getMissingGqlProperties'
    publishingRetailer?: {
        retailerReference?: string;
        retailerLocalReference?: string;
    };
    history?: {
        firstRegistrationDate?: string; // YYYY-MM-DD
        localFirstRegistrationDate?: string; // Used as a fallback for firstRegistrationDate if it is not available (see OR-8028)
        events?: {
            eventType: ReferenceType<DfServiceHistoryCode>;
            eventDescription: string;
            eventDate: string; // YYYY-MM-DD
            officialDealer: boolean;
            mileage: MeasureType;
        }[];
        previousAccident?: boolean;
        previousOwners?: number;
        previousUsage?: ReferenceType<DfPreviousUsageCode>;
    };
    promotion?: {
        label: string;
        effectiveTo: string;
        effectiveFrom: string;
        legalDisclaimer: string;
        promotionPrice: {
            reclaimableVAT: boolean;
            sellingPriceInclVAT: number;
            sellingPriceExclVAT: number;
        };
    };
    price?: {
        sellingPriceInclVAT: number;
        sellingPriceExclVAT: number;
        reclaimableVAT?: boolean;
        pricingSource?: ReferenceType<DfPricingSourceCode>;
    };
    descriptions?: { title: string }[];
    currency?: ReferenceType; // This seems like a bug at DF side.
    financingOffers?: {
        downPayment: string;
        duration: string;
        interestRate: string;
        effectiveInterestRate: string;
        // The "legalDisclaimer" field is still available for other NMSCs, no changes needed. This field will be deprecated soon in favor of legalDisclaimers.
        legalDisclaimer: string;
        legalDisclaimers: UsedCarsTranslationResultType[];
        monthlyPaymentInclVAT: string;
        product: string;
        residualValue: string;
        numberOfInstalments: string;
        productName?: string;
    }[];
    insuranceOffer?: {
        premium?: number;
        monthlyFee?: number;
        legalDisclaimer?: string;
        product?: string;
    };
    // Specs list this as required, but fairly sure this is optional for stock cars.
    vehicleValue?: { marketPrice?: number; cataloguePrice?: number; valuationDate: string; deliveryCost?: number };
    licensePlate: string;
    vin: string;
    ucProgram: ReferenceType;
    productionDate?: string;
    documents?: {
        label: ReferenceType<DfDocumentType>;
        hyperlink: string;
        documentCategory: ReferenceType<DfDocumentCategory>;
    }[];
    threeSixtyView?: {
        viewFormat: { code: string };
        parameters: { value: string }[];
    };
    ownershipTax?: number;
    productVersion: {
        displayBody: string;
        brand?: ReferenceType;
        model?: ReferenceType;
        subModel?: ReferenceType;
        modelYear?: string;
        bodyType?: ReferenceType;
        orderableSuffix?: { suffixCode: string; padocId: string };
        katashiki?: { fullKatashiki: string };
        doors: number;
        seats: number;
        length?: MeasureType;
        height?: MeasureType;
        width?: MeasureType;
        bootCapacity?: MeasureType;
        grade?: { gradeDescription?: string };
        productVersionIdentifiers?: {
            versionType: ReferenceType;
            versionCode: string;
            versionName: string;
        }[];
        // displayVersion is a required property but typed as optional. See 'getMissingGqlProperties'
        displayVersion?: string;
        carType: ReferenceType;
        engine: {
            description: string;
            // marketingFuelType is a required property but typed as optional. See 'getMissingGqlProperties'
            marketingFuelType?: ReferenceType<DfFuelTypeId>;
            cylinders?: number;
            powerOutputHorsepower: MeasureType;
            powerOutputKilowatts?: MeasureType;
            fiscalHorsepower: MeasureType;
            displacement: MeasureType;
            fuelType: ReferenceType;
            displayFuelType: string;
            hybrid: boolean;
            plugin?: boolean;
            engineKey?: ReferenceType;
            engineNumber?: string;
            batteryCapacity?: MeasureType;
        };
        // ⚠️ This will be mapped to eco (see formatters)
        emission?: {
            co2Emission?: EcoMeasureType[];
            ecoLabel?: ReferenceType;
            euroClass?: ReferenceType;
            efficiencyClass?: ReferenceType<DfEfficiencyClassCode>;
            // PHEV specific (empty battery efficiency class)
            efficiencyClassCSMode: ReferenceType<DfEfficiencyClassCode>;
            consumption?: EcoMeasureType[];
            electricRange: EcoMeasureType[];
        };
        performance: {
            maxSpeed: MeasureType;
            acceleration: MeasureType;
        };
        transmission: {
            displayTransmission: string;
            drivenWheels?: ReferenceType;
            transmissionType?: ReferenceType;
        };
        equipment: {
            equipmentType?: ReferenceType<DfEquipmentCategoryCode>;
            displayEquipment: string;
            show: boolean;
        }[];
        externalProductAttributes?: { key: ReferenceType; value: string }[];
        ssn?: {
            ssnCode: string;
        };
        tyreSpecs?: {
            tyres: { eprel: string }[];
            minimumNoise?: MeasureType;
            minimumWetGripClass?: string;
            minimumEfficiencyClass?: string;
        };
    };
    enrichmentStatus: {
        unavailabilityReasons?: string[];
    };
    nextInspectionDate?: string;
    isPledgedSale?: boolean;
    externalVehicleAttributes?: { key: ReferenceType; value: string }[];
};

// Supported aspect ratios throughout UCL
export type UscAspectRatioType = "16:9" | "4:3";

export type AemFuelTypeSpecNames = "global" | "petrol" | "diesel" | "electric" | "hydrogen" | "hybrid" | "pluginHybrid";

// Inverse of the dfFuelTypeIds enum
export type AemFuelTypeToDfFuelTypeMapType = {
    [key in DfFuelTypeId]: AemFuelTypeSpecNames;
};

export const aemFuelTypeToDfFuelTypeMap: AemFuelTypeToDfFuelTypeMapType = {
    [DfFuelTypeId.Petrol]: "petrol",
    [DfFuelTypeId.Diesel]: "diesel",
    [DfFuelTypeId.Electric]: "electric",
    [DfFuelTypeId.Hydrogen]: "hydrogen",
    [DfFuelTypeId.Hybrid]: "hybrid",
    [DfFuelTypeId.PluginHybrid]: "pluginHybrid",
    [DfFuelTypeId.Other]: "global",
};

export type LegalSpecConfigType = Partial<Record<AemFuelTypeSpecNames, LegalQuickSpecConfigType[]>>;

// Supported object fits throught UCL
export type UscObjectFitType = "contain" | "cover";
export type AEMQuickSpecType = {
    type: UscQuickSpec;
    title: string;
    tooltip?: string;
};

export type AEMSpecCategoryType = {
    type: SpecSection;
    title: string;
    specs: AEMQuickSpecType[];
};

/**
 * While this type was normally only ever used in USC,
 * for UC-876 we had to make it common as now the Car Filter
 * component also retrieves and uses the AEM USC settings -
 * specifically for the Customer Promise banners in dialogConfig.
 *
 * This is only relevant for the V2 Toyota PDP variant (TODO - remove this comment in UC-49)
 */
export type AEMUscSettingsType = {
    settings: Partial<{
        enableExternalPrice: BooleanStringType;
        pdpFeeBehaviour: string;
        enableFinance: BooleanStringType;
        showDealerWebsite: BooleanStringType;
        showDealerImpressum: BooleanStringType;
        enableInsurance: BooleanStringType;
        requireDefaultInsurance: BooleanStringType;
        reservationInfoUrl: string;
        showDealerEmail: BooleanStringType;
        defaultFinanceOption: "cash";
        showDealerPhoneNumber: BooleanStringType;
        ctas: string;
        localContactDealerFormUrl: string;
        localOnlineReservationUrl: string;
        pdpSections: string;
        showSimilarCars: BooleanStringType;
        enableBuy: BooleanStringType;
        enableReserve: BooleanStringType;
        taxLocations: string;
        enableSeoUrl: BooleanStringType;
        disabledEquipmentCategories: string;
        enableAccessories: BooleanStringType;
        disableAccessoryPrice: BooleanStringType;
        disableAccessoryMonthlyPrice: BooleanStringType;
        enableCarDelivery: BooleanStringType;
        carDeliveryInfoUrl: string;
        disableMonthInRegistrationSpec: BooleanStringType;
        showZeroCo2SpecValues: BooleanStringType;
        visibleVehicleHistoryEvents: string;
        enableDynamicCtas: BooleanStringType;
        dynamicCtasHideBuyCtaOnCash: BooleanStringType;
        dynamicCtasHideReserveCtaOnMonthly: BooleanStringType;
        enableLocalReservation: BooleanStringType;
        financeModuleUrl: string;
        monthlyPaymentUrl: string;
        aspectRatio: string;
        objectFit: string;
        hidePrintButton: BooleanStringType;
        hideFinanceTogglePrices: BooleanStringType;
        showFinanceLabelsOnCash: BooleanStringType;
        showCustomerSupportBanner: BooleanStringType;
        enableTDGEcolabel: BooleanStringType;
        enableServiceHistory: BooleanStringType;
        enableUcProgramBadges: BooleanStringType;
        enablePrivatelySoldVehicles: BooleanStringType;
    }>;
    labels: Partial<Record<UscLabelType, string>>;
    // eslint-disable-next-line @typescript-eslint/ban-types
    config: Partial<{
        // TODO: This is temmporary, figure out a better way of storing this.
        ecolabelTmefConfig: string;
    }>;
    dialogConfig?: Partial<{
        quickSpecsConfigs: AEMQuickSpecType[];
        /** @deprecated */
        quickSpecsTopConfigs: AEMQuickSpecType[]; // Backwards compatible - Remove during UC-926
        /** @deprecated */
        quickSpecsExtraConfigs: AEMQuickSpecType[]; // backwards compatible - Remove during UC-926
        specCategories: AEMSpecCategoryType[];
        /** @deprecated */
        ucProgramBannerDescriptionItems: AEMBannerDescriptionItemType[];
        /** @deprecated */
        nonUcProgramBannerDescriptionItems: AEMBannerDescriptionItemType[];
        // The following Customer Promise banner categories replace the deprecated banner description items above
        ucProgramCustomerPromiseBanner: UscCustomerPromiseBannerType;
        nonUcProgramCustomerPromiseBanner: UscCustomerPromiseBannerType;
        privatelySoldCustomerPromiseBanner: UscCustomerPromiseBannerType;
        externalPriceConfig: { key: string; label: string; disclaimer?: string }[];
        warrantyBannerUrl: string;
        hubBannerUrl: string;
    }>;
};

export enum UscVehicleSaleType {
    Private = "Private",
    Approved = "Approved",
    NonApproved = "NonApproved",
}

export type UscCustomerPromiseBannerType = {
    title: string;
    description?: string;
    bannerItems: AEMBannerDescriptionItemType[];
};
